<template>
	<v-container fluid>
		<v-app-bar fixed color="background" elevation="0" class="ios-bar py-lg-2 pr-lg-3">
			<v-btn icon rounded exact :to="{name: 'Account'}">
				<v-icon>mdi-arrow-left</v-icon>
			</v-btn>
			<v-spacer></v-spacer>
		</v-app-bar>
		<v-container>
			<v-row v-if="user.subscription_method === 'iap'">
				<v-col cols="12">
					<v-card class="pa-5 mt-5 text-center" color="secondary">
						<v-card-title class="mb-5 d-block text-h4">manage subscription</v-card-title>
						<v-card-text>to manage your subscription, please visit the <span v-if="$platform === 'ios'">App Store</span><span v-else>Play Store</span> app on your mobile device.</v-card-text>
						<v-card-text>
							<v-card color="accent">
								<v-card-text>IMPORTANT if you cancel your subscription your account will be kept for 60 days after your subscription ends, during this time you will not be able to add or edit memories.</v-card-text>
								<v-card-text>after the 60 days, if you have not re-subscribed, your account will be deleted forever.</v-card-text>
							</v-card>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
			<v-row v-else>
				<v-col cols="12">
					<v-card class="pa-5 mt-5 text-center" color="secondary">
						<v-card-title class="mb-5 d-block text-h4">manage subscription</v-card-title>
						<v-card-text>
							<v-row v-if="!showCancelDialog">
								<v-col cols="12" sm="8" lg="6" xl="5" class="mx-auto" v-if="subscription.subscription_method === 'stripe'">
									<v-card color="transparent">
										<v-card class="price text-start">
											<v-card-title class="text-h6 primary">{{ subscription.name }}</v-card-title>
											<template v-if="subscription.status === 'canceled'">
												<v-card-text class="text-h6 pt-10">
													status: <span class="ms-2 primary--text">{{ subscription.status }}</span> <br>
												</v-card-text>
												<v-card-text>your subscription will expire on {{ subscription.cancel_at }}</v-card-text>
											</template>
											<template v-else>
												<v-card-text class="text-h6 pt-10">
													status: <span class="ms-2 primary--text">{{ subscription.status }}</span> <br>
													billed: <span class="ms-2 primary--text">{{ subscription.billing_interval }}</span> <br>
													amount: <span class="ms-2 primary--text">{{ subscription.amount }}</span>
												</v-card-text>
											</template>

											<v-card-actions>
												<v-spacer></v-spacer>
												<v-btn v-if="subscription.status === 'active'" text @click="showCancelDialog = true" rounded class="px-5" color="grey">cancel subscription</v-btn>
											</v-card-actions>
										</v-card>
									</v-card>
								</v-col>
							</v-row>

							<v-row v-if="showCancelDialog">
								<v-col cols="12" sm="8" lg="6" xl="5" class="mx-auto">
									<v-card color="transparent">
										<v-card class="price text-start">
											<v-card-title class="text-h6 accent">cancel subscription</v-card-title>
											<v-card-text class="text-h6 pt-10">
												are you sure?
											</v-card-text>
											<v-card-text>your account will be kept for 60 days after your subscription ends, during this time you will not be able to add or edit memories.</v-card-text>
											<v-card-text>after the 60 days, if you have not re-subscribed, your account will be deleted forever.</v-card-text>
											<v-card-actions class="flex-wrap justify-center justify-lg-space-between">
												<v-btn @click="cancelSubscription" rounded class="px-5 mb-5 mb-lg-0" color="accent" text>cancel my subscription</v-btn>
												<v-btn @click="showCancelDialog = false" rounded class="px-5" color="accent">keep my subscription</v-btn>
											</v-card-actions>
										</v-card>
									</v-card>
								</v-col>
							</v-row>
							<r-stripe-prices v-if="subscription.status === 'canceled'"></r-stripe-prices>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</v-container>
</template>

<script>
import {toast} from "@/utils/helpers";
import {sync} from "vuex-pathify";

export default {
	name: "Show",
	metaInfo: {
		title: 'Manage Subscription'
	},
	computed: {
		user: sync('user/user')
	},
	data: () => ({
		subscription: {
			subscription_method: null,
		},
		showCancelDialog: false,
	}),
	beforeMount() {
		this.getSubscription()
	},
	methods: {
		getSubscription() {
			this.appLoading = true
			this.$api.subscriptions.show()
				.then(response => this.subscription = response.data)
				.catch(error => toast.add('Error fetching subscription', 'error'))
				.finally(() => this.appLoading = false)
		},
		cancelSubscription() {
			this.$api.subscriptions.cancel()
				.then(response => this.subscription = response.data)
				.catch(error => toast.add('Error fetching subscription', 'error'))
		}
	}
}
</script>